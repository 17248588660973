<template>
  <!-- 搜索组件 -->
  <el-form ref="formInlineBox" :inline="true" :model="formInline" class="form-search">
    <el-form-item
      v-for="item in searchFormItemArr"
      :key="item.value"
      :label-width="item.width || '80px'"
      :label="item.label"
      :prop="item.value"
    >
      <el-select
        v-if="item.type === 'select'"
        v-model="formInline[item.value]"
        size="small"
        :placeholder="`请选择${item.label}`"
        @change="$forceUpdate()"
      >
        <el-option label="全部" value />
        <el-option
          v-for="(val, index) in item.child"
          :key="index"
          :label="val[item.pLabel]"
          :value="val[item.pValue]"
        />
      </el-select>
      <el-input
        v-else-if="item.type === 'input'"
        v-model="formInline[item.value]"
        size="small"
        :placeholder="`请输入${item.label}`"
        clearable
        @input="$forceUpdate()"
      />
    </el-form-item>
    <el-form-item>
      <el-button v-if="getdata" size="small" type="primary" @click="getdata(false)">
        搜索
      </el-button>
      <el-button v-if="reset" size="small" type="primary" @click="resetForm">
        重置
      </el-button>
      <el-button v-if="addRow" size="small" type="primary" @click="addRow">
        添加
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
/**
 * formItemArr是项的数组  type是判断类型的   select为下拉框 input为输入框  label是属性名  value是属性
 * pLabel是下拉渲染的名称   pValue是下拉渲染的值  child是下拉的数组
 *
 * */
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    addRow: {
      type: Function
    },
    reset: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    getdata: {
      type: Function
    },
    formInline: {
      default: () => { return {} },
      type: Object
    },
    searchFormItemArr: {
      default: () => [],
      type: Array
    }
  },
  activated() {
    this.getdata(true)
  },
  methods: {
    resetForm() {
      this.$refs['formInlineBox'].resetFields()
      this.$forceUpdate()
      this.getdata()
    }
  }
}
</script>

<style scoped>
</style>
